import $ from 'jquery';

function doEmail() {
    /*<![CDATA[*/

    /***********************************************
     * Encrypt Email script- Please keep notice intact
     * Tool URL: http://www.dynamicdrive.com/emailriddler/
     * *********************************************
     <!-- Encrypted version of: info [at] *******.*** //-->*/
    const emailriddlerarray = [105, 110, 102, 111, 64, 97, 108, 45, 109, 101, 100, 97, 46, 99, 111, 109];
    let encryptedemail_id82 = ''; //variable to contain encrypted email
    for (let i = 0; i < emailriddlerarray.length; i++) {
        encryptedemail_id82 += String.fromCharCode(emailriddlerarray[i]);
    }

    window.open('mailto:' + encryptedemail_id82 + '?subject=Inquiry from the Al-Meda website');

    /*]]>*/
}

function setMenuOption(obj) {
    if (obj.value) { window.location.href = obj.value; }
}

$('document').ready(function () {
    const URL = document.URL;
    const currentPage = URL.substring(URL.lastIndexOf('/'));
    $('.nav-list a').removeClass('active');
    $('.nav-list a[href="' + currentPage + '"]').addClass('active');
    const page = "#" + window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    $(page).attr('selected', 'selected');

    $('#orderModal').on('show.bs.modal', function (event) {
      $(this).find('.modal-body').html($('div#' + $(event.relatedTarget).data('id')).text());
    });
});
